<template>
    <div class="footer">
        <div class="logo">
            <img src="@/assets/images/logo/logo_footer_min.svg" alt="Logo da empresa" width="300px">
        </div>

        <div class="social-networks">
            <img src="@/assets/icons/icon-whatsapp-blue-40x40-min.svg" alt="">
            <img src="@/assets/icons/icon-facebook-blue-40x40-min.svg" alt="">
            <img src="@/assets/icons/icon-instagram-blue-40x40-min.svg" alt="">
        </div>

        <div class="middle-content">
            <div class="address">
                <p>CNPJ: 03.279.735/0001-94</p>
                <p>Travessa Barão do Triunfo, 3540A - Sala 813</p>
                <p>Ed. Infinity Corporate Center</p>
                <p>Marco, Belém - PA - 66095-055</p>
            </div>

            <div class="contacts">
                <ul>
                    <li class="contact">
                        <img src="@/assets/icons/icon-call-blue-24x24-min.svg" alt="">
                        <p>+55 (91) 3726-3898</p>
                    </li>
                    <li class="contact">
                        <img src="@/assets/icons/icon-cellphone-blue-24x24-min.svg" alt="">
                        <p>+55 (91) 9 8977-6418</p>
                    </li>
                    <li class="contact">
                        <img src="@/assets/icons/icon-mail-blue-24x24-min.svg" alt="">
                        <p>comercial@maiatecnologia.com.br</p>
                    </li>
                </ul>
            </div>
        </div>

        <p class="privacy-policy">Consulte nossas <router-link :to="{ name: 'privacy-policy', hash: '#privacy-policy' }">Políticas de privacidade</router-link></p>

        <p class="copyright">@2022 Maia Produção de Softwares Ltda - Todos os direitos reservados</p>
    </div>
</template>

<style scoped>
.social-networks {
    display: flex;
    padding: 20px 0;
}
.address,
.contacts,
.privacy-policy {
    font-size: 10px;
    margin-bottom: 20px;
}
.address p {
    font-size: 10px;
    padding-bottom: 4px;
}
.contact {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}
.contact img {
    margin-right: 20px;
}
.contact p {
    font-size: 10px;
}
.privacy-policy,
.copyright {
    font-size: 10px;
    text-align: center;
}
@media(min-width: 500px) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media(min-width: 1000px) {
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
    }
    .social-networks {
        display: flex;
        align-self: center;
        gap: 20px;
    }
    .middle-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 100px;
    }
    .privacy-policy {
        margin-bottom: 10px;
    }
    .privacy-policy a:hover {
        color: var(--a2);
        text-decoration: underline;
    }
}
</style>