<template>
  <div class="bg-cookies" v-if="isOpen">
    <div class="cookies">
        <p class="message">{{ message }}</p>
        <p class="links">Ver nossa <router-link :to="{ name: 'privacy-policy', hash: '#privacy-policy' }">Políticas de privacidade</router-link></p>
        <div class="buttons">
            <button class="btn accept-cookies" @click="accept">Aceitar e continuar</button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'CookieMessage',
    data () {
        return {
            isOpen: true,
            message: 'Usamos cookies para personalizar conteúdo e anúncios, fornecer recursos de mídia social e analisar nosso tráfego.Também compartilhamos informações sobre seu uso do nosso site com nossos parceiros de mídia social, publicidade e análise, que podem combiná-los com outras informações que você forneceu a eles ou que eles coletaram do uso de seus serviços.'
        }
    },
    created() {
        if(this.cookiesAccepted() === 'true') {
            this.isOpen = false
        }
    },
    methods: {
        cookiesAccepted() {
            return localStorage.getItem('GDPR:accepted', true)
        },
        accept() {
            this.isOpen = false
            return localStorage.setItem('GDPR:accepted', true)
        }
    }
}
</script>

<style scoped>
    .bg-cookies {
        position: fixed;
        width: 100%;
        bottom: 1rem;
        z-index: 1000;
    }
    .cookies {
        border: 1px solid var(--c5);
        background: var(--c1);
        padding: 14px;
        max-width: 90%;
        border-radius: 10px;
        margin: 0 auto;
        display: grid;
        gap: 20px ;
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

        animation: slideUp .5s forwards;
        opacity: 0;
        transform: translateY(1rem);
    }

    @keyframes slideUp {
        to {
            transform: initial;
            opacity: initial;
        }
    }
    .cookies>.message {
        color: var(--c5);
        font-size: 0.875rem !important;
        text-align: left;
        letter-spacing: 0.06em;
        line-height: 1.3rem;
    }
    .cookies>.links  {
        font-size: 0.875rem !important;
        font-size: 2px;
        color: var(--c5);
        letter-spacing: 0.06em;
    }
    .cookies a {
        color: var(--a2);
        border-bottom: 1px solid transparent;
        font-weight: 600;
    }
    .cookies a:hover {
        border-bottom: 1px solid var(--a2);
    }
    .btn {
        color: var(--white);
        background: var(--a2);
        border: 2px solid var(--a2);
        cursor: pointer;
    }
    @media (min-width: 751px) {
        .bg-cookies {
            opacity: 0.8;
        }
        .cookies {
            padding: 14px;
            width: 1000px;
        }
        .cookies>.message {
            font-size: 0.875rem !important;
        }
        .cookies>.links  {
            font-size: 0.875rem;
        }
    }
</style>