<template>
  <div id="page">
    <CookieMessage />

    <div class="header">
      <HeaderComponent />
    </div>

    <main>
      <router-view/>
    </main>

    <div class="bg-footer">
      <footer class="content">
        <FooterComponent />
      </footer>
    </div>

    <div class="top-btn">
      <button @click="scrollTop()">
        <img src="@/assets/icons/icon-arrow-top-white-40x40-min.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import CookieMessage from '@/components/cookies/CookieMessage.vue'
import HeaderComponent from '@/components/header/HeaderComponent.vue';
import FooterComponent from '@/components/footer/FooterComponent.vue';

export default {
  name: 'AppComponent',
  components: {
    CookieMessage,
    HeaderComponent,
    FooterComponent
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
  html {
    scroll-behavior: smooth;
  }
  body {
    display: flex;
    flex-direction: column;
  }
  main {
    flex-grow: 1;
    padding-top: 102px
  }
  footer {
    padding: 20px;
  }
  .top-btn {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
  .top-btn button{
    padding: 10px;
    border-radius: 100%;
    opacity: 0.8;
    background: var(--v1);
    border: none;
  }
</style>
