<template>
<div class="bg-header">
    <header class="content header" :class="{'scrolled-nav' : scrollNav}">
        <div class="logo">
            <img src="@/assets/images/logo/logo_description_min.svg" alt="Logo da empresa" width="340">
        </div>

        <div>
            <Navbar />
        </div>
    </header>
</div>
</template>

<script>
import Navbar from '@/components/header/NavbarComponent.vue'
// import NavbarMobile from '@/components/header/NavbarMobileComponent.vue'

export default {
    name: 'HeaderComponent',
    components: {
        Navbar
        // NavbarMobile
    },
    // mounted() {
    //     window.addEventListener('scroll', this.updateScroll)
    // },
    // data () {
    //     return {
    //         scrollNav: null,
    //     }
    // },
    // methods: {
    //   updateScroll() {
    //     const scrollPosition = window.scrollY
    //     if(scrollPosition > 50) {
    //         this.scrollNav = true
    //         return
    //     }
    //     this.scrollNav = false
    //     },
    // }
}
</script>

<style scoped>
    .bg-header {
        position: fixed;
        background: var(--white);
        width: 100vw;
        z-index: 9999;
        top: 0;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }
    /* .bg-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: deepskyblue;
    } */
    /* .header { */
        /* background: var(--c1); */
        /* display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box; */
        /* padding: 20px; */
        /* position: fixed; */
        /* top: 0; */
        /* width: 100vw; */
        /* z-index: 9999; */
        /* background: red; */
        /* background: red; */
    /* } */
    @media(min-width: 1000px) {
        .bg-header {
            background: var(--white);
        }
    }
</style>