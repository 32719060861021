<template>
  <nav class="nav">
    <ul class="navigation" v-if="!mobile">
      <li><router-link :to="{ name: 'home', hash: '#home' }">Home</router-link></li>
      <li><router-link :to="{ name: 'about', hash: '#page' }">A empresa</router-link></li>
      <li><router-link :to="{ name: 'home', hash: '#our-products' }">Nossos produtos</router-link></li>
      <li><router-link :to="{ name: 'home', hash: '#our-advantages' }">Vantages</router-link></li>
      <li><router-link :to="{ name: 'home', hash: '#talking-about' }">O que estão falando</router-link></li>
      <li><router-link :to="{ name: 'home', hash: '#contact' }">Contato</router-link></li>
    </ul>

    <transition name="mobile-nav">
      <ul class="mobile-menu dropdown-nav" v-if="toggleSideBar">
          <li class="close" @click="toggleSideBar = !toggleSideBar">
              <img src="@/assets/icons/icon-close40x40-min.svg" alt="Ocultar menu">
          </li>
          <li><router-link :to="{ name: 'home', hash: '#page' }" @click="toggleSideBar = false">Home</router-link></li>
          <li><router-link :to="{ name: 'about', hash: '#page' }" @click="toggleSideBar = false">A empresa</router-link></li>
          <li><router-link :to="{ name: 'home', hash: '#our-products' }" @click="toggleSideBar = false">Nossos produtos</router-link></li>
          <li><router-link :to="{ name: 'home', hash: '#our-advantages' }" @click="toggleSideBar = false">Vantages</router-link></li>
          <li><router-link :to="{ name: 'home', hash: '#talking-about' }" @click="toggleSideBar = false">O que estão falando</router-link></li>
          <li><router-link :to="{ name: 'home', hash: '#contact' }" @click="toggleSideBar = false">Contato</router-link></li>
          <li><router-link :to="{ name: 'privacy-policy'}" @click="toggleSideBar = false">Políticas de privacidade</router-link></li>
      </ul>
    </transition>

    <div class="icon">
        <img src="@/assets/icons/more_vert_32x32_min.svg" alt="" @click="toggleSideBar = !toggleSideBar" v-show="mobile" >
    </div>

  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent',
  data () {
    return {
      mobile: true,
      toggleSideBar: false,
    }
  },
  created () {
    window.addEventListener('resize', this.checkscreen)
    this.checkscreen()
  },
  methods: {
    checkscreen() {
      if(window.innerWidth <= 1122) {
        this.mobile = true
        this.toggleSideBar = false
        return
      }

      this.mobile = false
      this.toggleSideBar = false
      return
    }
  }
}
</script>

<style scoped>
.navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.navigation > li > a {
    color: var(--v1);
    font-weight: 700;
    padding: 10px;
    font-family: var(--sans-serif);
    letter-spacing: 0.02em;
}
.navigation > li > a:hover {
    border-bottom: 2px solid var(--a2);
    color: var(--a2);
}

/** Mobile */
.dropdown-nav {
  background: var(--a2);
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.dropdown-nav li:not(.close) {
  font-size: 1.25rem;
  font-family: var(--sans-serif);
  font-weight: 400;
  text-transform: uppercase;
  padding: 20px;
}
.dropdown-nav li>a {
  color: var(--white);
  opacity: .8;
}
.close {
  border: 1px solid var(--white);
  border-radius: 50px;
  padding: 4px;
  background: rgba(255, 255, 255, 0.267);
  position: absolute;
  top: 20px;
  right: 20px;
}
.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 2s ease all;
}
.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateY(-100%);
}
</style>