import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueGtag from 'vue-gtag'
import './plugins/firebase'

import './assets/css/style.css'

// createApp(App).use(store).use(router).mount('#app')

createApp(App)
.use(store)
.use(router)
.use(VueGtag, {
    config: {
        id: 'G-LNEB1Z0RN3',
        enabled: true
    },
}, router ).mount('#app')
