// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7ia6aAKrXxujQLohno2I8XIivfGZCPtc",
  authDomain: "ju-financeiro.firebaseapp.com",
  databaseURL: "https://ju-financeiro-default-rtdb.firebaseio.com",
  projectId: "ju-financeiro",
  storageBucket: "ju-financeiro.appspot.com",
  messagingSenderId: "13019588208",
  appId: "1:13019588208:web:8bdfe59dea4b5290b776b5"
};

// Initialize Firebase
initializeApp(firebaseConfig);